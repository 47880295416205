import { Email, Launch, LinkedIn } from '@mui/icons-material';
import { AppBar, Box, Container, IconButton, Link, Toolbar, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

type IconData = {
  Icon: React.ElementType;
  link: string;
  tooltip: string;
};

const icons: IconData[] = [
  {
    Icon: LinkedIn,
    link: 'https://www.linkedin.com/in/cory-jbara/',
    tooltip: 'LinkedIn',
  },
  {
    Icon: Email,
    link: 'mailto:cjbara@gmail.com',
    tooltip: 'Email',
  },
];

const navItems = [
  {
    name: 'Newsletter',
    link: 'https://blog.coryjbara.com',
    newTab: true,
    showExternal: true,
  },
];

export const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = isHomePage ? window.scrollY > 200 : true;
      const shouldShowTitle = isHomePage ? window.scrollY > 200 : true;
      setScrolled(isScrolled);
      setShowTitle(shouldShowTitle);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isHomePage]);

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: scrolled ? 'white' : 'transparent',
        transition: 'all 0.3s',
        boxShadow: scrolled ? 1 : 'none',
      }}
    >
      <Container maxWidth="lg">
        <Toolbar
          disableGutters
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              opacity: showTitle ? 1 : 0,
              transform: showTitle ? 'translateY(0)' : 'translateY(-10px)',
              transition: 'all 0.3s',
            }}
          >
            <Box
              component="img"
              src="/cory4.jpeg"
              alt="Cory Jbara"
              sx={{
                width: 40,
                height: 40,
                borderRadius: '8px',
                objectFit: 'cover',
              }}
            />
            <Typography
              variant="h6"
              sx={{
                color: 'primary.main',
                fontWeight: 'bold',
              }}
            >
              Cory Jbara
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
            }}
          >
            {navItems.map(({ name, link, newTab, showExternal }) => (
              <Link
                key={name}
                color="primary.main"
                href={newTab ? link : `#${link}`}
                target={newTab ? '_blank' : undefined}
                rel={newTab ? 'noopener noreferrer' : undefined}
                underline="none"
                sx={{
                  '&:hover': { opacity: 0.7 },
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                }}
              >
                {name}
                {showExternal && <Launch sx={{ fontSize: 14, opacity: 0.6 }} />}
              </Link>
            ))}
            <Box sx={{ display: 'flex' }}>
              {icons.map(({ Icon, link, tooltip }) => (
                <Tooltip title={tooltip} key={tooltip}>
                  <IconButton aria-label={tooltip} color="primary" href={link} target="_blank">
                    <Icon />
                  </IconButton>
                </Tooltip>
              ))}
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
